<script>
// Extensions
import View from "@/views/View";

// Mixins
import LoadSections from "@/mixins/load-sections";

export default {
  name: "Home",

  metaInfo: { title: "Home" },

  extends: View,

  mixins: [
    LoadSections([
      "hero",
      //"valueProposition",
      "toWho",
      "process",
      "features",
      "showcase",
      //"theme-features",
      "carousel",
      "pro-features",
      //"newsletter",
      "contact-us",
      "faq"
      //'affiliates',
      //'social-media',
      //"keep-in-touch",
      //'info',
    ])
  ],

  props: {
    id: {
      type: String,
      default: "home"
    }
  }
};
</script>
